<template>
  <div class="coianter">
    <div class="flexrow tiecg">
      <div class="tilelft">
        <div style="margin-right: 10px">{{ form.proName }}</div>
        <div>
          <span style="font-size:14px;margin-right:10px">当前状态:</span>
          <el-tag size="small" :style="{ 'background-color': statusform.rgb, 'color': 'white' }">{{ statusform.name
            }}</el-tag>
            {{ formStatus }}
        </div>
      </div>
      <div class="tieright">
        <el-button type="primary" @click="goback">返回</el-button>
      </div>
    </div>
    <div class="idteail">
      <div style="padding-left: 10px">
        <el-tabs v-model="activeName" @tab-click="handleClicktab" type="border-card">
          <!-- <el-tab-pane label="认证基本信息" name="first">
            <lixiangmanger  @changeNowType="changeNowType"  :ddata="this.form"  />
          </el-tab-pane> -->
          <el-tab-pane label="形式检查" name="second" :disabled="formStatus < 1.20">
            <draftReview @changeNowType="changeNowType" :ddata="form" />
          </el-tab-pane>
          <el-tab-pane label="专家初审" name="third" :disabled="formStatus < 3.05">
            <solicitingOpinions @changeNowType="changeNowType"  :ddata="form"/>      
          </el-tab-pane>
          <el-tab-pane v-if="isAdmin==1" label="认证前公示" name="fourth" :disabled="formStatus < 4.05">
            <examination @changeNowType="changeNowType"  :ddata="form"/>
          </el-tab-pane>
          <el-tab-pane v-if="isAdmin==1"  label="抽检验证" name="five" :disabled="formStatus < 5.0">
            <keeponrecord @changeNowType="changeNowType" :ddata="form"/>
          </el-tab-pane>
          <el-tab-pane v-if="isAdmin==1"  label="专家认证" name="six" :disabled="formStatus < 6.0">
            <expertCertificate @changeNowType="changeNowType" :ddata="form"/>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>

import { getBiaozhunDetail, addBiaozhun } from "@api/project";
import draftReview from "./draftReviewqy.vue";
import solicitingOpinions from "./solicitingOpinions.vue";
import examination from "./examination.vue";
import keeponrecord from "./keeponrecord.vue";
import expertCertificate from "./expertCertificate.vue";
import moment from "moment";
import { fetchStatusList } from "@utils/tools";

export default {

  components: {
    draftReview,  
    solicitingOpinions,
    examination,
    keeponrecord,
    expertCertificate
  },
  data() {
    return {
      nowType: 1,
      htmlTitle: "页面导出PDF文件名",
      labelPosition: "right",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],
      form: {
        stars: 1,
        shencha: "",
        status: '1.10'
      },
      countyOptions: [],
      value1: "",
      value2: "",
      value3: "",
      activeName: "second",
      tableData: [],
      tableData2: [
        {
          start_time: "",
          endtime: "",
          worktext: "",
        },
        {
          start_time: "",
          endtime: "",
          worktext2: "",
        },
        {
          start_time: "",
          endtime: "",
          worktext3: "",
        },
        {
          start_time: "",
          endtime: "",
          worktext4: "",
        },
        {
          start_time: "",
          endtime: "",
          worktext5: "",
        },
      ],
      textName: "",
      id: "",
      token: "",
      page: 1, //第几页
      size: 3, //一页多少条
      total: 0, //总条目数
      formStatus: 0,
      statusform: {},
      isAdmin:""

    };
  },
  // create(){
  //   this.getIdAndToken();
  // },
  mounted() {
    this.getIdAndToken();
  },
  methods: {   
    changeNowType(nowType) {
      this.nowType = nowType;
    },
    goback() {
      this.$router.go(-1);
    },
    momentBytime(date) {
      let y = moment(date).format("YYYY-MM-DD");
      return y;
    },
    getIdAndToken() {
      this.id = this.$route.query.id;
      this.token = this.$store.state.token; //获取token
      this.isAdmin=this.$store.state.user.type;
      this.getInfo();
    },
    // 获取详情
    async getInfo() {
      try {
        const res = await getBiaozhunDetail({ id: this.id, token: this.token });
        this.formStatus = parseFloat(res.data.status);       
        this.statusform = await fetchStatusList(res.data.status, this.page, this.token); // 在组件创建时调用接口获取状态列表
        this.htmlTitle = res.data.proName;
        this.form = res.data;
        this.tableData = res.data.fu; // 主要起草人员展开无分页
      } catch (err) {
        console.log(err);
      }
    },
    //切换导航
    handleClicktab(tab, event) {
    },
    //图片上传成功
    photo_success(re, file, fileList) {
      // console.log(fileList);
    },
    fileUploadSuccess(response, file, fileList) {

      let tmpList = fileList.slice(fileList.length - 1);
      this.fileList = tmpList.map((item) => {
        return item.response.data;
      });
    },   

    gotoStep() { 
      this.$router.push({
        path: "../../formal/proDetailDownLoad?id=" + this.id,
      });
    },
    
    //退回 修改状态
    changeStatus() {
      this.form.status = 3;
      this.form.token = this.token;
      // console.log(this.form);
      addBiaozhun(this.form).then((rec) => {
        // console.log("修改状态",rec);
      });
      this.$router.push({
        path: "../../formal/formalPend",
      });
    },
    handleRemove(file) {
      console.log(file, "2");
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      // console.log(file, "1");
    },

    //page改变时的回调函数，参数为当前页码
    currentChange(val) {
      // console.log("翻页，当前为第几页", val);
      this.page = val;
      this.getInfo();
    },
    //size改变时回调的函数，参数为当前的size
    sizeChange(val) {
      // console.log("改变每页多少条，当前一页多少条数据", val);
      this.size = val;
      this.page = 1;
      this.getInfo();
    },
  },
};
</script>
<style scoped>
/deep/.el-tabs__item {
  font-size: 20px;
  font-weight: 600;
  width: 17%;
  text-align: center;
}

/deep/.el-tabs__nav {
  background-color: transparent;
  width: 100%;
}

/deep/.el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-input {
  width: 250px;
}
</style>
<style lang="scss" scoped>
.coianter {
  width: 100%;
  height: 800px;
}

.flexrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  height: 60px;
  line-height: 60px;
  margin-bottom: 20px;
}

.idteail {
  margin: 0 0 0px 0px;
  width: 100%;

  // height:60px;
  // background-color: #ffffff;
  // box-shadow: #cccbcb 0px -1px 10px;
  .idtname {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin-left: 63px;
  }

  .back {
    margin-right: 23px;
    font-size: 20px;
  }
}

.title {
  background: white;
  width: 100%;

  .titleicon {
    margin: 10px;
    width: 4px;
    height: 24px;
    background: #3082e2;
  }

  .titletext {
    font-size: 22px;
    font-family: Adobe Heiti Std;
    color: #3082e2;
    line-height: 65px;
  }
}

.comit {
  padding: 20px 0;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: white;

  .baocun {
    margin: 0 25px;
  }
}

.name_input {
  width: 100%;
}

.name_input>.el-form-item {
  width: 100%;
}

.name_input>.el-form-item .el-input {
  width: 100%;
}

.borderstyle {
  border: 1px solid;
}

.centerstyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flexstyle {
  display: flex;
  flex-direction: row;
}

.tableTitle-tr {
  height: 60px;
  font-size: 16px;

  .tdtitle {
    width: 150px;
    border: 1px solid #3082e2;
    background: #eaf2fc;
    text-align: center;
  }

  .tdcontent {
    width: 500px;
  }

  .tdcontent2 {
    width: 300px;
  }

  .bianzhitd {
    width: 1415px;
  }
}

.tableTitle-tr2 {
  font-size: 16px;

  .tdtitle {
    width: 150px;
    border: 1px solid black;
    background: #eaf2fc;
    text-align: center;
  }

  .shijianstyle {
    text-align: center;
  }

  .tdcontent {
    width: 500px;
  }

  .tdcontent2 {
    width: 300px;
  }

  .bianzhitd {
    width: 1415px;
  }
}

.tiwen {
  height: 160px;
  box-shadow: 0px 6px 11px 2px rgba(72, 77, 84, 0.2);

  .tiwenText {
    width: 100%;
    height: 100%;
    background: rgba(93, 160, 236, 0.1);
    border: 0px;
    resize: none;
  }
}

.borderstyle {
  border: 1px solid;
}

table {
  border: 0;
  border-collapse: collapse;
}

.tableTitle-tr-pdf {
  height: 60px;
  font-size: 16px;
  border: 1px solid #000305;

  .tdtitle {
    width: 150px;
    border: 1px solid black;
    //background: #EAF2FC;
    text-align: center;
  }

  .tdcontent {
    border: 1px solid #000305;
    width: 500px;
  }

  .tdcontent2 {
    border: 1px solid #000305;
    width: 300px;
  }

  .bianzhitd {
    border: 1px solid #000305;
    width: 1415px;
  }
}

td {
  border: 1px solid #3082e2;
}

//这里改
.tilelft {
  padding-left: 20px;
}

.tieright {
  padding-right: 20px;
}

.tilelft {
  font-size: 22px;
  font-weight: bold;
  color: #1a1a1a;
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
