<template>
  <div>
    <div class="flexrow" v-if="formStatus > 1.40 || formStatus <= 5.30">
      <div class="leftcg">
        <div class="flexrow lefttie" style="background-color: #ffffff">
          <div class="ltleft">
            审查资料
            <a :href="downloadLink" download="初稿审查资料模板" style="color: #3086fb;  font-size: 12px">
              <el-button type="danger" round size="mini">{{ checkdata.templete.name }}<i
                  class="el-icon-bottom"></i></el-button>
            </a>
          </div>
          <!-- <div class="ltright">
            <div v-if="formStatus > 1.40 || formStatus <= 5.30"
              style="font-size: 12px; color: #f56c6c; margin-top: 5px">
              请在8个月内上传完成
              <span>{{ getLeftTime() }}</span>
            </div>
          </div> -->
        </div>
        <div class="leftfile">
          <div class="filelistcg">
            <div v-for="(item, index) in checkdata.uploadfiles" class="cgfiles" :key="index">
              <div class="flexrow cgtop">
                <div class="cgfleft">
                  <div>
                    {{ item.name }}
                    <el-tag v-if="item.isupload" effect="dark" size="mini" type="danger">必须上传</el-tag>
                  </div>
                </div>
                <div class="cgfrig">
                  <el-upload accept=".pdf,.jpg,.png,.doc,.docx" :before-upload="showLoad"
                    :data="{ token: $store.state.token }" :file-list="item.fileList"
                    :on-success="(response) => changeMaterImg(item, response)" :show-file-list="false"
                    :on-remove="(file, fileList) => removeImg(item, file, fileList)" action="api/public/bupload"
                    class="upload-demo">
                    <el-button size="small" type="primary" :disabled="!isValueInList">上传文件 </el-button>
                  </el-upload>
                </div>
              </div>
              <!-- <div class="flexrow cgtop" v-for="(file, fileIndex) in item.fileList" @click="zhanshi2(item, fileIndex)"
                :key="fileIndex"> -->
              <div class="flexrow cgtop" v-for="(file, fileIndex) in item.fileList" :key="fileIndex">
                <div class="cgfleft">
                  <div>
                    <div style="color: #999999" class="filename">
                      {{ file.name }}
                    </div>
                  </div>
                </div>
                <div class="cgfrig" @click="delbtn(item, fileIndex)" >删除</div>
              </div>
            </div>

          </div>
        </div>
        <div class="sendbtn">
          <el-button type="success" @click="addmit" :disabled="disabled" v-if="isValueInList">提交资料</el-button>
          <el-button type="success" :disabled="todisabled" v-else-if="form.status == 5.1">已提交在审核中</el-button>
          <el-button type="success" :disabled="todisabled" v-else>已完成</el-button>
        </div>
      </div>
      <div class="rightcg">
        <div class="lefttie" style="margin-bottom: 10px; border-bottom: none">
          <div class="ltleft">审查意见</div>
        </div>
        <div v-for="(activity, index) in activities" :key="index">
          <div class="kuang">
            <div class="cr-card">
              <div class="checkcont">
                {{ activity.content }}
              </div>
            </div>
            <div class="chfiles">
              <div class="cgfleft" v-for="(item, index) in activeFile(activity.type)" :key="index">
                <el-tooltip class="item" effect="light" :content="item.name" placement="bottom">
                  <div class="cgfleftto" @click="zhanshi(item)">
                    {{ item.name }}
                  </div>
                </el-tooltip>
              </div>
            </div>
            <div class="linetie">
              <div class="lttime">
                <i class="el-icon-time"></i>
                {{ activity.created_at | formatDate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { enterpriseFiles, enterpriseFileDel } from "@api/project";
import { formatDate, shenchaType } from '@utils/filters';
export default {
  props: {
    ddata: {
      type: Object,
      required: true,
    }
  },
  filters: {
    shenchaType,
    formatDate,
  },
  computed: {
    downloadLink() {
      return this.checkdata.templete.file;
    },
    isValueInList() {
      return this.disagreelist.includes(this.formStatus);
    },
  },
  data() {
    return {
      disagreelist: [ '6.05','6.20', '6.40', '6.60'], //当这里面数值时可以提交数据
      // 审核资料数据结构
      checkdata: {
        "stage": "exportCheckend",
        "stagetypeid": '5',
        "templete": {
          "name": "模板下载",
          "file": "https://api.gbservice.cn/storage/uploads/standard/5ae5061594f0899cec0c7392580a5492.zip",
          "delaytime": 8
        },
        "uploadfiles": [
          {
            "orderid": 1,
            "name": "认证意见",
            "isupload": true,
            "fileList": []
          },
          {
            "orderid": 2,
            "name": "专家名单",
            "isupload": true,
            "fileList": []
          }
         
        ]
      },
      materImgList7: [],
      nowType: 1,
      reverse: false,
      types: [],
      activities: [],
      form: {},
      disabled: false,
      todisabled: true,
      loadingInstance: "",
      formStatus: 0,
      stageid: 2,
      
    };
  },
  mounted() {
    this.form = this.$props.ddata;

    this.$watch('ddata', (newVal) => {
      this.form = newVal;
      this.formStatus = this.form.status;
      this.stageid = parseInt(this.formStatus);
      this.getfiles();

    });
    this.getIdAndToken();

  },
  methods: {
    // 将获取的标准列表的文件
    getfiles() {

      if (this.form.files.length != 0) {
        this.form.files.forEach((file) => {
      
          if (file.type==this.checkdata.stagetypeid) {
            const uploadFile = this.checkdata.uploadfiles.find((f) => f.orderid === file.orderid);
            if (uploadFile) {
              uploadFile.fileList.push(file);
            }
          }

        });
      }
    },
    zhanshi2(item) {
      window.open(item.url);
    },
    zhanshi(item) {
      window.open(item.url);
    },
    activeFile(type) {
      if (type == 1 || type == 4) {
        return this.materImgList7.filter((item) => item.type == 30);
      } else if (type == 2 || type == 5) {
        return this.materImgList7.filter((item) => item.type == 31);
      } else if (type == 3 || type == 6) {
        return this.materImgList7.filter((item) => item.type == 32);
      } else if (type == 7) {
        return this.materImgList7.filter((item) => item.type == 33);
      }
    },
    getLeftTime() {
      //开始的毫秒数
      let start;
      if (this.activities && this.activities.length > 0) {
        start = this.activities[this.activities.length - 1].updated_at;
      } else {
        start = this.form.updated_at;
      }
      //到期的时间 毫秒数
      let end = new Date(start).getTime() + 8 * 30 * 24 * 60 * 60 * 1000;
      //剩余的毫秒数
      let left = end - new Date().getTime();

      let day = parseInt(left / (24 * 60 * 60 * 1000)) % 30;
      let month = parseInt(left / (30 * 24 * 60 * 60 * 1000));
      let result = day + "天截止";
      if (month) {
        result = month + "个月" + result;
      }
      return "还剩余" + result;
    },

    showNowType() {
      let lastType = 0;
      if (this.activities.length > 0) {
        lastType = this.activities[this.activities.length - 1].type;
      }
      if (lastType == 0 || lastType == 4 || lastType == 5 || lastType == 6) {
        this.nowType = 1;
      } else if (lastType == 1) {
        this.nowType = 2;
      } else if (lastType == 2) {
        this.nowType = 3;
      } else if (lastType == 3) {
        this.nowType = 7;
      } else {
        this.nowType = -1;
      }
      this.$emit("changeNowType", this.nowType == -1 ? 7 : this.nowType);
    },
    // 上传之前进行格式和文件大小限制
    showLoad(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ["pdf", "jpg", "png", "doc", "docx"];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message("上传文件只能是 pdf、jpg、png、doc、docx格式", "error");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 16;
      if (!isLt2M) {
        this.$message("上传文件大小不能超过 16MB", "error");
      }
      return isLt2M;
    },

    goback() {
      this.$router.go(-1);
    },

    changeMaterImg(item, response) {

      // console.log('respones',item)
      var uploadedFile = {
        name: response.data.name,
        url: response.data.url,
        orderid: item.orderid
      };
      item.fileList.push(uploadedFile);

    },

    removeImg(item, file, fileList) {
      const index = fileList.findIndex(f => f === file);

      this.$confirm(`是否确认删除文件 ${file.name}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        fileList.splice(index, 1);
      }).catch(() => {

      });
    },
    // 删除上传的图片
    delbtn(item, fileIndex) {
      const fileToRemove = item.fileList[fileIndex];
      this.$confirm(`是否确认删除文件 ${fileToRemove.name}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        item.fileList.splice(fileIndex, 1);
        // fileToRemove.isdelete = 1;
        // fileToRemove.token = this.$store.state.token;
        let param = {
          token: this.$store.state.token,
          id: fileToRemove.id,
          isdelete: 1
        }

        enterpriseFileDel(param)
          .then((res) => {
            this.$message.success("删除成功！");
            // this.getIdAndToken();          
          })
          .catch((err) => {

          });
      }).catch(() => {
        this.$message("删除失败");
      });


    },
    // 提交审查
    addmit() {
      // TODO 这里需要增加对提交文件数量的判断，应该当没有提交文件时不给提交，尤其是必须提交的文件
      this.disabled = true;
      this.$confirm(`确定提交？ `, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'information'
      }).then(() => {
        let files_mb = [];
        for (let item of this.checkdata.uploadfiles) {
          files_mb = files_mb.concat(item.fileList);
        }
        // 设置当前阶段
        files_mb.forEach((item) => {
          item.type = this.checkdata.stagetypeid;
          if (
            this.stageid == 1
          ) {
            item.stageid = this.stageid + 1;
          } else {
            item.stageid = this.stageid;
          }

        });

        let lctype = 0;
        if (this.activities?.length > 0) {
          lctype = this.activities[this.activities.length - 1].type;
        }

        let types = [];
        for (let i = 0; i < files_mb.length; i++) {
          types.push(i + 1);
        }

        let params = {
          token: this.$store.state.token,
          files: files_mb,
          sid: this.$route.query.id,
          status: "6.10",//初审提交
          type: "1",
          types: types.join(","),
        };

        enterpriseFiles(params)
          .then((res) => {
            this.$message.success("提交审查成功");
            this.getIdAndToken();
          })
          .catch((err) => {
            this.disabled = false;
          });
      }).catch(() => {
        this.$message("提交失败！");
      });


    },
    // 获取用户信息
    getIdAndToken() {
      this.id = this.$route.query.id;
      this.token = this.$store.state.token; //获取token
    }
  },
};
</script>

<style scoped lang="scss">
.filename {
  cursor: pointer;
}

.filename:hover {
  color: #3082e2;
}

.chfiles {
  border-top: 1px solid #d8d8d8;
  margin-top: 10px;
  padding-top: 10px;
}

.cgfleftto {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.cgfleftto:hover {
  color: #3082e2;
  cursor: pointer;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.shehe {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.flexrow_tt {
  display: flex;
  flex-direction: row;
}

.checktitle {
  font-size: 18px;
  font-weight: bold;
  color: #999999;
  padding-top: 3px;
}

.sendbtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.flexrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tilelft {
  font-size: 22px;
  font-weight: bold;
  color: #1a1a1a;
}

.tiecg {
  margin-bottom: 20px;
}

.lefttie {
  border-bottom: 1px solid #dbe9ef;
  padding: 5px;

  .ltleft {
    border-left: 4px solid #3082e2;
    padding-left: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #3082e2;
  }

  .ltright {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.leftcg {
  width: 64%;
}

.rightcg {
  width: 35%;
  height: 73vh;
  overflow: hidden;
  padding-right: 20px;
  overflow-y: scroll;
  padding-left: 40px;
}

.rightcg::-webkit-scrollbar {
  display: none;
}

.linetie {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  margin-top: 15px;

  .lttime {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }

  .ltmanger {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    padding-right: 10px;
  }
}

.kuang {
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.29);
  margin: 10px 0;
  padding: 10px 15px;
}

.cr-card {
  .checkcont {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
  }
}

.tgbtn {
  padding: 10px 58px;
}

.leftfile {
  padding: 10px 20px;
  background-color: #ffffff;

  .filelistcg {
    .cgfiles {
      background: #f4f4f4;
      padding: 20px 15px;
      margin-bottom: 10px;

      .cgtop:nth-child(1) {
        border-bottom: 1px solid #dbe9ef;
      }

      .cgtop:nth-child(2) {
        margin-top: 10px;
      }

      .cgfleft {
        font-size: 16px;
        font-weight: 400;
        width: 80%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #666666;

        .beizhu {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          padding-left: 15px;
          height: 35px;
          line-height: 35px;
        }
      }

      .cgfrig {
        font-size: 14px;
        font-weight: 400;
        color: #f56c6c;
        cursor: pointer;
      }

      .cgfrig:hover {
        font-size: 16px;
        font-weight: 400;
        color: #d84747;
        cursor: pointer;
      }
    }
  }
}
</style>
