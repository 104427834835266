<template>
  <div>

    <div>
      <div class="" style="margin: 0 auto; width: 100%; background: white">
        <div style="margin: 20px; margin-top: 1px; padding-top: 30px">
          <table>
            <tr class="tableTitle-tr">
              <td class="tdtitle">标准名称</td>
              <td class="tdcontent">{{ form.proName }}</td>
              <td class="tdtitle">标准类型</td>
              <td>
                <span v-if="form.biaozhunType == 1">标准</span>
                <span v-if="form.biaozhunType == 2">标准设计</span>
                <span v-if="form.biaozhunType == 3">推荐性技术规程</span>
              </td>
              <td class="tdtitle">制定类型</td>
              <td>
                <span v-if="form.zhidingType == 1">制定</span>
                <span v-if="form.zhidingType == 2">修订</span>
              </td>
            </tr>
            <tr class="tableTitle-tr">
              <td class="tdtitle">编制单位</td>
              <td>{{ form.firstUnit }}</td>
              <td class="tdtitle">地址</td>
              <td class="tdcontent2">{{ form.firstAddress }}</td>
              <td class="tdtitle">邮编</td>
              <td class="tdcontent2">{{ form.postcode }}</td>

            </tr>
            <tr class="tableTitle-tr">
              <td class="tdtitle">单位负责人</td>
              <td>{{ form.firstHandler }}</td>
              <td class="tdtitle">联系电话</td>
              <td class="tdcontent2">{{ form.firstTel }}</td>
              <td class="tdtitle">电子邮箱</td>
              <td class="tdcontent2">{{ form.firstEmail }}</td>
            </tr>
            <!-- <tr class="tableTitle-tr">
              <td class="tdtitle">项目负责人</td>
              <td>{{ form.firstProHandler }}</td>
              <td class="tdtitle">联系电话</td>
              <td>{{ form.firstProHandlerTel }}</td>
              <td class="tdtitle">电子邮箱</td>
              <td>{{ form.firstProHandlerEmail }}</td>
            </tr> -->
            <tr class="tableTitle-tr">
              <td class="tdtitle">项目联系人</td>
              <td>{{ form.firstProContact }}</td>
              <td class="tdtitle">联系电话</td>
              <td>{{ form.firstProContactTel }}</td>
              <td class="tdtitle">电子邮箱</td>
              <td>{{ form.firstProContactEmail }}</td>
            </tr>
            <tr class="tableTitle-tr">
              <td class="tdtitle" >企业简介</td>
              <td colspan="5">{{ form.content }}</td>             
            </tr>

          </table>

        </div>
      </div>
    </div>

    <el-dialog :visible.sync="batch_show" title="输入立项编号" width="30%">
      <el-input v-model="number" placeholder="请输入立项编号"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batch_show = false">取 消</el-button>
        <el-button type="primary" @click="add_batch">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import { addBiaozhun } from "@api/project";
import draftReview from "./draftReviewqy.vue";

export default {
  props: {
    ddata: {
      type: Object,
      required: true,
    }
  },
  components: { draftReview },
  data() {
    return {
      number: "",
      batch_show: false,

      form: {},
      tableData: [],
      tableData2: [
        {
          start_time: "",
          endtime: "",
          worktext: "",
        },
        {
          start_time: "",
          endtime: "",
          worktext2: "",
        },
        {
          start_time: "",
          endtime: "",
          worktext3: "",
        },
        {
          start_time: "",
          endtime: "",
          worktext4: "",
        },
        {
          start_time: "",
          endtime: "",
          worktext5: "",
        },
      ],
      id: "",
      token: "",
      size: 3, //一页多少条
      isAdmin: 0, //是否是管理员
    };
  },

  mounted() {
    this.form = this.$props.ddata;
    // 观察数据的变化
    this.$watch('ddata', (newVal, oldVal) => {
      this.form = newVal;
    });
    this.getIdAndToken();
  },
  methods: {
    // 填写项目编码后立项
    add_batch() {
      this.form.number = this.number;
      this.form.status = '1.40';
      this.form.token = this.token;
      addBiaozhun(this.form).then((rec) => {
        this.batch_show = false;
        this.$message({
          showClose: true,
          message: "立项成功",
          type: "success",
        });
        //立项成功到初审
        this.$router.push({
          path: "../../formal/formalPend",
        });
      });

    },
    // 不立项
    bulixiang() {

      if (this.form.status == 1.2) {
        this.form.status = 1.3;
        this.form.token = this.token;
        addBiaozhun(this.form).then((rec) => {
          this.$router.push({
            path: "../../formal/formalPend",
          });
          this.$message.warning("项目不立项成功！");
        });
      } else {
        this.$message.warning("项目状态不支持立项！");
      }
    },
    changeNowType(nowType) {
      this.nowType = nowType;
    },

    getIdAndToken() {
      this.id = this.$route.query.id;
      this.token = this.$store.state.token; //获取token
      this.isAdmin = this.$store.state.role[0];
    },

    gotoStep() {
      this.$router.push({
        path: "../../formal/proDetailDownLoad?id=" + this.id,
      });

    },
    // 管理员提交项目立项
    lxiangStandard() {
      if (this.form.status == '1.20') {
        this.batch_show = true;
      } else {
        this.$message.warning("项目状态不支持立项");
      }
    },

    // 用户提交项目
    saveProject() {
      let copyRen = this.form.ren;
      this.form.status = 1.20; //已提交未立项
      (this.form.token = this.$store.state.token), console.log(this.form);
      addBiaozhun(this.form).then((rec) => {
        this.$router.push({
          path: "/user_project/itemIdent",
        });
        this.$message({
          message: "提交成功",
          type: "success",
        });
      });
    },

    //退回 修改状态
    changeStatus() {

      if (this.form.status == 1.2) {
        this.form.status = 1.16;
        this.form.token = this.token;
        addBiaozhun(this.form).then((rec) => {
          this.$router.push({
            path: "../../formal/formalPend",
          });
          this.$message.warning("退回成功！");
        });
      } else {
        this.$message.warning("项目状态不支持退回！");
      }


    },

  },
};
</script>
<style scoped>
/deep/ .el-input {
  width: 250px;
}
</style>
<style lang="scss" scoped>
.coianter {
  width: 100%;
  background-color: #f4f4f4f4;
  height: 800px;
}

.idteail {
  margin: 0 0 0px 0px;
  width: 100%;
  // height:60px;
  background-color: #ffffff;

  // box-shadow: #cccbcb 0px -1px 10px;
  .idtname {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin-left: 63px;
  }

  .back {
    margin-right: 23px;
    font-size: 20px;
  }
}

.title {
  background: white;
  width: 100%;

  .titleicon {
    margin: 10px;
    width: 4px;
    height: 24px;
    background: #3082e2;
  }

  .titletext {
    font-size: 22px;
    font-family: Adobe Heiti Std;
    color: #3082e2;
    line-height: 65px;
  }
}

.comit {
  padding: 20px 0;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: white;

  .baocun {
    margin: 0 25px;
  }
}

.name_input {
  width: 100%;
}

.name_input>.el-form-item {
  width: 100%;
}

.name_input>.el-form-item .el-input {
  width: 100%;
}

.borderstyle {
  border: 1px solid;
}

.centerstyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flexstyle {
  display: flex;
  flex-direction: row;
}

.tableTitle-tr {
  height: 60px;
  font-size: 16px;

  .tdtitle {
    width: 150px;
    border: 1px solid #3082e2;
    background: #eaf2fc;
    text-align: center;
  }

  .tdcontent {
    width: 500px;
  }

  .tdcontent2 {
    width: 300px;
  }

  .bianzhitd {
    width: 1415px;
  }
}

.tableTitle-tr2 {
  font-size: 16px;

  .tdtitle {
    width: 150px;
    border: 1px solid black;
    background: #eaf2fc;
    text-align: center;
  }

  .shijianstyle {
    text-align: center;
  }

  .tdcontent {
    width: 500px;
  }

  .tdcontent2 {
    width: 300px;
  }

  .bianzhitd {
    width: 1415px;
  }
}

.tiwen {
  height: 160px;
  box-shadow: 0px 6px 11px 2px rgba(72, 77, 84, 0.2);

  .tiwenText {
    width: 100%;
    height: 100%;
    background: rgba(93, 160, 236, 0.1);
    border: 0px;
    resize: none;
  }
}

.borderstyle {
  border: 1px solid;
}

table {
  border: 0;
  border-collapse: collapse;
}

.tableTitle-tr-pdf {
  height: 60px;
  font-size: 16px;
  border: 1px solid #000305;

  .tdtitle {
    width: 150px;
    border: 1px solid black;
    //background: #EAF2FC;
    text-align: center;
  }

  .tdcontent {
    border: 1px solid #000305;
    width: 500px;
  }

  .tdcontent2 {
    border: 1px solid #000305;
    width: 300px;
  }

  .bianzhitd {
    border: 1px solid #000305;
    width: 1415px;
  }
}

td {
  border: 1px solid #3082e2;
}

//这里改</style>
